<template>
  <div class="card" v-loading="loading">
    <div class="editor p-6">
      <div class="editor__post-fields d-flex">
        <div class="editor__main pe-3">
          <div class="editor__post-head">
            <div class="editor__post-title">
              <!--begin::Label-->
              <label class="form-label">Sarlavha</label>
                <!--end::Label-->
                <el-input
                  v-model="formData.title"
                  autosize
                  type="textarea"
                  placeholder="Please input"
                  @blur="generateSlug()"
                />
            </div>
            <div class="editor__post-slug">
              <div class="mt-3 mb-6">
                <label class="form-label">Slug</label>
                <el-input type="textarea" autosize v-model="formData.slug" placeholder="Please input"/>  
              </div>
            </div>
          </div>
          <div v-if="editor">
                <div class="editor__content">
                  <editor-menu :editor="editor"/>
                  <editor-content :editor="editor" />
                </div>
            </div>
        </div>
        <div class="editor__little ps-3">
          <div class="mb-3">
            <label class="form-label required">Yangilik turi</label>
            <el-select v-model="formData.type"  @change="checkType" placeholder="Select" >
              <el-option
                v-for="item in typees"
                :key="item._id"
                :label="item.title"
                :value="item._id"
              />
            </el-select>
          </div>
          <div class="mb-3">
            <label class="form-label required">Yo'nalish</label>
            <el-select @change="selectCategory" v-model="formData.category" placeholder="Select" >
              <el-option
                v-for="item in categories"
                :key="item._id"
                :label="item.title"
                :value="item._id"
              />
            </el-select>
          </div>
          <div class="mb-3">
            <label class="form-label required">Turkum</label>
            <el-select v-model="formData.subcategory" placeholder="Select" >
              <el-option
                v-for="item in subcategories"
                :key="item._id"
                :label="item.title"
                :value="item._id"
              />
            </el-select>
          </div>
          <div v-if="typeVal == 'img'" class="">
            <label class="form-label">Rasm</label>
            <el-upload
              v-model:file-list="fileList"
              ref="uploadRef"
              class="upload-item"
              :action = imgUrl
              :auto-upload="true"
              :limit="1"
            >
              <template #trigger>
                <el-button >Faylni tanlang</el-button>
              </template>
              <template #tip>
                <div class="el-upload__tip">
                  jpg/png fayllar o'lchami 500kb dan kam bo'lishi kerak
                </div>
              </template>
            </el-upload>
          </div>  
          <div v-else-if="typeVal == 'video'" class="">
            <label class="form-label required">Video yo'li</label>
            <el-input v-model="formData.videolink" placeholder="Please input"/>  
          </div>
          <div  v-else-if="typeVal == 'shortvideo'" class="">
            <label class="form-label">Rasm</label>
            <el-upload
              v-model:file-list="imgList"
              ref="uploadRef"
              class="upload-item"
              :action = imgUrl
              :auto-upload="true"
              :limit="1"
            >
              <template #trigger>
                <el-button >Faylni tanlang</el-button>
              </template>
              <template #tip>
                <div class="el-upload__tip">
                  mp4 fayl yuklang
                </div>
              </template>
            </el-upload>
            <label class="form-label">Video</label>
            <el-upload
              v-model:file-list="fileList"
              ref="uploadRef"
              class="upload-item"
              :action = "videoUrl"
              :auto-upload="true"
              :limit="1"
              :headers="headers"
            >
              <template #trigger>
                <el-button >Video faylni yuklang</el-button>
              </template>
              <template #tip>
                <div class="el-upload__tip">
                  mp4 fayl
                </div>
              </template>
            </el-upload>
          </div>
          <div class="">
            <label class="form-label required">Tags</label>
            <el-select
              v-model="formData.tags"
              multiple
              filterable
              allow-create
              default-first-option
              :reserve-keyword="false"
              placeholder="Choose tags for your article"
            >
              <el-option
                v-for="item in tags"
                :key="item._id"
                :label="item.name"
                :value="item._id"
              />
            </el-select>
          </div>
        </div>
      </div>
      <div class="editor__post-seo">
        <el-divider content-position="left">Seo</el-divider>
        <div class="fields__row d-flex justify-content-between mb-5 gap-5">
          <div class="width-50">
            <label class="form-label required">Meta title</label>
            <el-input v-model="formData.meta_title" placeholder="Please input"/>  
          </div>
          <div class="width-50">
            <label class="form-label required">Meta Keyword</label>
            <el-input v-model="formData.meta_keyword" placeholder="Please input"/>  
          </div>
        </div>
        <div class="fields__row d-flex justify-content-between mb-5 gap-5">
          <div class="width-50">
            <!--begin::Label-->
            <label class="form-label mb-3"> Meta Description</label>
            <!--end::Label-->
            <el-input
              v-model="formData.meta_desc"
              type="textarea"
              placeholder="Please input"
            />
          </div>
          <div class="width-50">
            <!--begin::Label-->
            <label class="form-label mb-3">Meta Script</label>
            <!--end::Label-->
            <el-input
              v-model="formData.meta_script"
              type="textarea"
              placeholder="Please input"
            />
          </div>
        </div>
      </div>
      <div class="editor__footer">
        <button type="button " class="post_btn danger me-5" @click="$router.go(-1)">Bekor qilish</button>
        <button class="post_btn" @click="postData(editor)">Qo'shish</button>
      </div>
    </div>
  </div>
  <!-- <pre>{{ formData }}</pre> -->
</template>

<script>
import EditorMenu from "@/components/editor/EditorMenu.vue"
import { useEditor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
// import BulletList from '@tiptap/extension-bullet-list'
// import Document from '@tiptap/extension-document'
// import ListItem from '@tiptap/extension-list-item'
// import OrderedList from '@tiptap/extension-ordered-list'
// import Paragraph from '@tiptap/extension-paragraph'
// import Text from '@tiptap/extension-text'
// import Heading from '@tiptap/extension-heading'
import Image from '@tiptap/extension-image';
import Focus from '@tiptap/extension-focus';
// import Code from '@tiptap/extension-code'
import TextAlign from '@tiptap/extension-text-align';
import { useRouter } from 'vue-router';
import ApiService from '@/core/services/ApiService';
import { onMounted, ref, computed } from "vue";
import { Actions } from '@/store/enums/StoreEnums'
import { useStore } from 'vuex'



export default {
  components: {
    EditorContent,
    EditorMenu
  },

  setup() {
    const editor = useEditor({
      content: '',
      extensions: [
        StarterKit,
        Underline,
        // Document,
        // Paragraph,
        // Text,
        // BulletList,
        // OrderedList,
        // ListItem,
        // Heading,
        Image,
        // Code, 
        Focus.configure({
          className: 'has-focus',
          mode: 'all',
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        })
      ],
      // onUpdate({editor}){
      //   const html = editor.getHTML();
      //   console.log(html);
      // }
    });

    const router = useRouter()
    const store = useStore()
    const formData = ref({tags: []});

    const fileList = ref([]);
    const imgList = ref([]);
    const loading = ref(false)

    const subcategories = computed(()=>{
        return store.getters.alSubategories
    })

    const tags = computed(()=>{
        return store.getters.alTags
    })

    const typees = computed(()=>{
        return store.getters.alTypees
    })
    const categories = computed(()=>{
        return store.getters.alCategories
    })

    const imgUrl = process.env.VUE_APP_API_URL+'/news/img';
    const videoUrl = process.env.VUE_APP_API_URL+'/news/video';

    const postData = async(editor)=>{
      
      formData.value.text = editor.getHTML()
      if(imgList.value.length > 0){
        formData.value.img = imgList.value[0].response
      }
      if(fileList.value.length > 0){
        formData.value.video = fileList.value[0].response
      }
      
      await ApiService.post('news', formData.value).then(res =>{
        if(res.status == 201){
          router.push('/news')
        }
      })
    }

    const generateSlug = async()=>{
      loading.value = true;
      await ApiService.post('news/genslug', {title: formData.value.title}).then(res =>{
        formData.value.slug = res.data.slug;
        loading.value = false;
      })
    }

    const selectCategory = async  () =>{        
      await store.dispatch(Actions.ALL_SUBCATEGORIES, {params:{limit: 0, category: formData.value.category}});
    }


    onMounted(async() =>{
      await store.dispatch(Actions.ALL_CATEGORIES, {params:{limit: 0}});
      await store.dispatch(Actions.ALL_SUBCATEGORIES, {params:{limit: 0}});
      await store.dispatch(Actions.ALL_TAGS, {params:{limit: 0}});
      await store.dispatch(Actions.ALL_TYPEES, {params:{limit: 0}});
    })

    const typeVal = ref('');
    const checkType = ()=>{
      typees.value.forEach(item =>{
        if(item._id == formData.value.type){
          typeVal.value = item.slug
        }
      })
    }


    return { selectCategory, editor, postData, formData, subcategories, tags, imgUrl, videoUrl, fileList, typees, categories, generateSlug, checkType, typeVal, imgList }
  }
}
</script>

<style lang="scss">
.ProseMirror {
    position: relative;
    padding: 10px;
    // border: 1px solid ;
    &:focus-visible{
      outline: none;
    }
    border: 1px solid rgb(217, 218, 221);
    border-radius: 5px;
    min-height: 145px;
}
.dvider{
  background: rgb(217, 218, 221);
  height: 28px;
  width: 1px;
  margin-right: 10px;
}

.editor{
  &__little{
    flex: auto;
  }
  &__footer{
    display: flex;
    justify-content: flex-end;
  }
}
.post_btn{
    border-radius: 5px;
    background-color: #009EF7;
    color: #fff;
    margin-top: 10px;
    border: none;
    padding: 10px 35px;
    font-size: 14px;
    &.danger{
      background-color: rgb(240, 86, 76);
    }
}
.upload-item{
  width: 100%;
  & .el-upload{
    width: 100%;
  }
  & button{
    width: 100%;
    color: rgb(184, 183, 186);
  }
}

.width-50{
  width: 50%;
}
.width-33{
  width: 33.33333%;
}
.width-25{
  width: 25%;
}
</style>